<!--  -->
<template>
  <div>
    <el-card style="height: 700px; overflow: auto" >
       <h3 v-show="ruleForm.auditReject">退回理由：{{ ruleForm.auditReject }}</h3>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item label="公司名称" prop="companyName" label-width="135">
          <el-input v-model="ruleForm.companyName" size="small"></el-input>
        </el-form-item>
        <el-form-item label="公司类型" prop="companyType" label-width="135">
          <el-select
            @change="firmStatusChange"
            size="small"
            v-model="ruleForm.companyType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in firmStatus"
              :key="item.dictName"
              :label="item.dictDescribe"
              :value="item.dictName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="统一社会信用代码"
          prop="creditCode"
          label-width="135"
        >
          <el-input v-model="ruleForm.creditCode" size="small"></el-input>
        </el-form-item>
        <el-form-item
          label="注册资本"
          prop="registeredCapital"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.registeredCapital"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="成立日期" prop="foundDate" label-width="135">
          <el-date-picker
            size="small"
            value-format=yyyy/MM/dd
            v-model="ruleForm.foundDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="经营范围" prop="businessScope" label-width="135">
          <el-input v-model="ruleForm.businessScope" size="small"></el-input>
        </el-form-item>
        <div class="address">
          <el-form-item
            label="公司地址"
            prop="companyProvince"
            label-width="135"
            style="width: 100%"
          >
          <div class="address_box">
            <el-select
              v-model="ruleForm.companyProvince"
              placeholder="请选择省"
              @change="provinceChange"
              size="small"
            >
              <el-option
                v-for="(item, index) in provinceList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.companyCity"
              placeholder="请选择市"
              @change="cityChange"
              size="small"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option> </el-select
            ><el-select
              v-model="ruleForm.companyArea"
              placeholder="请选择区"
              @change="areaChange"
              size="small"
            >
              <el-option
                v-for="(item, index) in areaList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
          </div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="ruleForm.companyAddress"
            placeholder="公司详细地址"
            style="width: 50%;margin-top: 10px; margin-left: 135px"
          >
          </el-input>
          </el-form-item>
        </div>
        <el-form-item
          label="登记机关"
          prop="registerDepartment"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.registerDepartment"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司人数" prop="employeesNumberVar" label-width="135">
          <el-select
            @change="firmNumChange"
            size="small"
            v-model="ruleForm.employeesNumberVar"
            placeholder="请选择"
          >
            <el-option
              v-for="item in firmNumList"
              :key="item.dictName"
              :label="item.dictDescribe"
              :value="item.dictName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="公司联系人"
          prop="companyContacts"
          label-width="135"
        >
          <el-input v-model="ruleForm.companyContacts" size="small"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人电话"
          prop="companyContactsPhone"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.companyContactsPhone"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="法人姓名"
          prop="artificialPerson"
          label-width="135"
        >
          <el-input v-model="ruleForm.artificialPerson" size="small"></el-input>
        </el-form-item>
        <el-form-item
          label="法人身份证"
          prop="artificialPersonIdcard"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.artificialPersonIdcard"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="法人电话"
          prop="artificialPersonPhone"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.artificialPersonPhone"
            size="small"
            style="width: 47%"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="bank" label-width="135">
          <el-input v-model="ruleForm.bank" size="small"></el-input>
        </el-form-item>
        <el-form-item label="公司账号" prop="bankAccount" label-width="135">
          <el-input v-model="ruleForm.bankAccount" size="small"></el-input>
        </el-form-item>
        <div class="firm" style="width: 100%">
          <el-form-item
            label="公司简介"
            prop="companyProfile"
            label-width="135"
            style="width: 100%"
        >
          <el-input
            type="textarea"
            v-model="ruleForm.companyProfile"
            :rows="2"
            placeholder="请输入内容"
          >
          </el-input>
        </el-form-item>
        </div>
    
        <el-form-item
          label="营业执照信息"
          prop="businessLicense"
          label-width="135"
          style="width: 50%"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="ruleForm.businessLicense"
              :src="ruleForm.businessLicense"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="法人身份证图片"
          prop="idCardImg"
          label-width="135"
          style="width: 50%"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="handleAvatarSuccesss"
          >
            <img
              v-if="ruleForm.idCardImg"
              :src="ruleForm.idCardImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
 
      </el-form>
      <div class="btn">
        <el-button @click="back">取消</el-button>
        <el-button @click="submit" class="submit" :loading="loading"
          >提交认证</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getAuthInfo,
  saveAuthInfo,
  getSelect,
  getCityTree,
  getCitySelect,
} from '@/api/user'
import { upload } from '@/api/upload'

export default {
  props: {
    isFirmShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/
      if (value === '' || !reg.test(value)) {
        return callback(new Error('请输入正确格式的手机号码 '))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      ruleForm: {
        companyName: '',
        companyTypeName: '',
        companyType: '',
        creditCode: '',
        registeredCapital: '',
        foundDate: '',
        businessScope: '',
        companyAddress: '',
        companyProvince: '',
        companyProvinceCode: '',
        companyCity: '',
        companyCityCode: '',
        companyArea: '',
        companyAreaCode: '',
        registerDepartment: '',
        employeesNumberVar: '',
        employeesNumber: '',
        companyContacts: '',
        companyContactsPhone: '',
        artificialPerson: '',
        artificialPersonIdcard: '',
        artificialPersonPhone: '',
        idCardImg: '',
        bank: '',
        bankAccount: '',
        companyProfile: '',
        businessLicense: '',
      },
      rules: {
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        companyType: [
          { required: true, message: '请选择公司类型', trigger: 'change' },
        ],
        creditCode: [
          {
            required: true,
            message: '请输入统一社会信用代码',
            trigger: 'blur',
          },
        ],
        registeredCapital: [
          { required: true, message: '请输入注册资本', trigger: 'blur' },
        ],
        foundDate: [
          { required: true, message: '请选择成立日期', trigger: 'change' },
        ],
        businessScope: [
          { required: true, message: '请输入经营范围', trigger: 'blur' },
        ],
        companyProvince: [
          { required: true, message: '请选择公司地址', trigger: 'change' },
        ],
        registerDepartment: [
          { required: true, message: '请输入登记机关', trigger: 'blur' },
        ],
        employeesNumberVar: [
          { required: true, message: '请选择公司人数', trigger: 'change' },
        ],
        companyContacts: [
          { required: true, message: '请输入公司联系人', trigger: 'blur' },
        ],
        companyContactsPhone: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        artificialPerson: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
        ],
        artificialPersonIdcard: [
          { required: true, message: '请输入法人身份证', trigger: 'blur' },
        ],
        artificialPersonPhone: [
          { required: true, message: '请输入法人电话', trigger: 'blur' },
        ],
        bank: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        bankAccount: [
          { required: true, message: '请输入公司账号', trigger: 'blur' },
        ],
        businessLicense: [
          { required: true, message: '请上传营业执照信息', trigger: 'blur' },
        ],
        idCardImg: [
          { required: true, message: '请上传法人身份证图片', trigger: 'blur' },
        ],
        companyProfile: [
          { required: true, message: '请输入公司简介', trigger: 'blur' },
        ],
      },
      provinceList: [],
      cityList: [],
      areaList: [],
      firmNumList: [],
      firmStatus: [],
      action: upload,
      isShowForm: true,
    }
  },
  mounted() {
    this.getAuthInfo()
    this.getSelect()
    this.getCityTree()
  },
  methods: {
    getAuthInfo() {
      getAuthInfo().then((res) => {
        if (res.data == null) {
          this.isShowForm = false
        } else {
          this.isShowForm = true
        }
        if (res.data != null) {
          this.ruleForm = res.data
        }
      })
    },
    getSelect() {
      getSelect('employeesNumber').then((res) => {
        if (res.code == 200) {
          this.firmNumList = res.data
        }
      })
      getSelect('companyType').then((res) => {
        if (res.code == 200) {
          this.firmStatus = res.data
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.businessLicense = res.data.url
    },
     handleAvatarSuccesss(res, file) {
      this.ruleForm.idCardImg = res.data.url
    },
    back() {
      this.$router.go(-1)
    },
    getCityTree() {
      getCityTree().then((res) => {
        this.provinceList = res.data
      })
    },
    provinceChange(e) {
      let provinceObj = {}
      provinceObj = this.provinceList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.companyProvince = provinceObj.cityName
      this.ruleForm.companyProvinceCode = provinceObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.cityList = res.data
      })
    },
    cityChange(e) {
      let cityObj = {}
      cityObj = this.cityList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.companyCity = cityObj.cityName
      this.ruleForm.companyCityCode = cityObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.areaList = res.data
      })
    },
    areaChange(e) {
      let countyObj = {}
      countyObj = this.areaList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.companyArea = countyObj.cityName
      this.ruleForm.companyAreaCode = countyObj.cityId
    },
    firmNumChange(e) {
      let dictObj = {}
      dictObj = this.firmNumList.find((item) => {
        return item.dictName === e
      })
      this.ruleForm.employeesNumberVar = dictObj.dictDescribe
      this.ruleForm.employeesNumber = dictObj.dictName
      console.log(this.ruleForm)
    },
    firmStatusChange(e) {
      let dictObj = {}
      dictObj = this.firmStatus.find((item) => {
        return item.dictName === e
      })
      console.log(dictObj)
      this.ruleForm.companyTypeName = dictObj.dictDescribe
      this.ruleForm.companyType = dictObj.dictName
      console.log(this.ruleForm.companyTypeName)
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          saveAuthInfo(this.ruleForm).then((res) => {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
            this.loading = false
            this.back()
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  .el-input {
    width: 47%;
  }
  .el-form-item {
    width: 50%;
    margin-bottom: 20px;
  }
  ::v-deep .el-form-item__label {
    width: 135px;
  }
  .el-textarea {
    width: 50%;
  }
  .address_box {
    display: flex;
    .el-select {
      margin-right: 20px;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 146px;
    height: 146px;
    line-height: 146px;
    text-align: center;
    border: 1px dashed #ccc;
    margin: 10px 0;
  }
  .avatar {
    width: 146px;
    height: 146px;
    display: block;
    object-fit: contain;
  }
}
::v-deep .el-form-item__error {
  left: 30%;
}
::v-deep .el-form .address .el-form-item:nth-child(1) .el-form-item__error {
  left: 20%;
  margin-top: 3px;
}
::v-deep .el-form .firm .el-form-item:nth-child(1) .el-form-item__error {
  left: 15%;
  margin-top: 5px;
}
.btn {
  display: flex;
  justify-content: right;
}
</style>
